import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Root from './Root';

// Custom Landing Pages
const MyAllyHomePage = React.lazy(() => import('../pages/MyAllyHomePage'));
const CustomERP = React.lazy(() => import('../pages/CustomERP'));
const TurnkeyWebDev = React.lazy(() => import('../pages/TurnkeyWebDev'));
const Career = React.lazy(() => import('../pages/Career'));
const DoneForYouClub = React.lazy(() => import('../pages/DoneForYouClub')); 

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={MyAllyHomePage} />,
                },
                {
                    path: 'custom-erp',
                    element: <LoadComponent component={CustomERP} />,
                },
                {
                    path: 'turnkey-web-dev',
                    element: <LoadComponent component={TurnkeyWebDev} />,
                },
                {
                    path: 'done-for-you-club',
                    element: <LoadComponent component={DoneForYouClub} />,
                },
                {
                    path: 'careers',
                    element: <LoadComponent component={Career} />,
                },
            ],
        },
    ]);
};

export default AllRoutes;
