import firebase from 'firebase/compat/app';
// import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

let firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyB9Q3DeGmSWoDMRbinsqy5T29VS7HocqbE',
    authDomain: 'myallypublic.firebaseapp.com',
    projectId: 'myallypublic',
    storageBucket: 'myallypublic.appspot.com',
    messagingSenderId: '80524512131',
    appId: '1:80524512131:web:cc031c2774d839925e84b6',
    measurementId: 'G-48DEQ4C34P',
});

const analytics = firebase.analytics();

// const functions = firebaseApp.functions();
const firestore = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebase.storage();

let nodeEnv = process.env.NODE_ENV || 'development';

//LOCAL DEBUGGING CLOUD FUNCTIONS
if (nodeEnv === 'development') {
    // auth.useEmulator('http://localhost:9099');
    // firestore.useEmulator('localhost', 8080);
    // functions.useEmulator('localhost', 5001);
    // storage.useEmulator('localhost', 9199);
}

export default firebaseApp;

export { firestore, auth, storage, analytics };
// export { functions, firestore, auth, storage, analytics };
